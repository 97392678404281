import { addDays, isTomorrow, isValid, subHours } from 'date-fns';
import { centralEuropeanNow, ignoreTimezoneExtension } from '../../../../play-core/utils/date-helper';
import { isTvDayTodayCET, startOfTvDayHour } from '../../components/TvGuide/tv-guide-types';
import { isoDate, shortDateFormat } from '../../utils/date-formatter';
export const getTvGuideDefaultDate = () =>
// TV Day is from 05.00-05.00 in switzerland so we need to subtract 5 hours to get the correct default date
subHours(centralEuropeanNow(), startOfTvDayHour);
const getFallbackDate = fallBack => {
  switch (fallBack) {
    case 'tomorrow':
      return addDays(getTvGuideDefaultDate(), 1);
    default:
      return getTvGuideDefaultDate();
  }
};
export const getValidDate = (dateString, fallBack = 'today') => {
  const date = dateString ? new Date(dateString + ignoreTimezoneExtension) : getFallbackDate(fallBack);
  return isValid(date) ? date : getFallbackDate(fallBack);
};
export const getTvGuideDateVariant = date => {
  switch (true) {
    case isTvDayTodayCET(isoDate(date)):
      return 'today';
    case isTomorrow(date):
      return 'tomorrow';
    default:
      return 'date';
  }
};
export const getTvGuideMeta = (t, date) => {
  switch (getTvGuideDateVariant(date)) {
    case 'tomorrow':
      return {
        title: t('global.tvGuideTomorrow'),
        description: t('missedShow.metaDescriptionTomorrow')
      };
    case 'date':
      return {
        title: t('global.tvGuideDate', {
          date: shortDateFormat(date)
        }),
        description: t('missedShow.metaDescription', {
          date: shortDateFormat(date)
        })
      };
    default:
      return {
        title: t('global.tvGuideToday'),
        description: t('missedShow.metaDescriptionToday')
      };
  }
};